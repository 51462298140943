.root {
  position: relative;
  align-self: stretch;
  background-color: var(--white-color);
  border-radius: 8px;
  display: flex;
  width: 495px;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .arrivalInfo {
      display: flex;
      padding: 6px 6px 6px 6px;
      align-items: flex-start;
      gap: 10px;

      &.notArrived {
        width: 251px;
        border-radius: 4px;
        border: 1px solid var(--highlight-yellow-color);
        background: var(--highlight-yellow-color);
      }
    }

    .timeInfo {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;

      .strong {
        font-weight: 600;
      }

      &.header {
        font-weight: 500;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 10px;
    width: 100%;

    .trashIcon {
      align-self: flex-end;
      margin-right: 0px;
      margin-left: auto;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
      text-transform: capitalize;

      &.arrived {
        height: 35px;
        width: 120px;
        color: var(--dark-green-color);
        border: 1px solid var(--dark-green-color);
        background: var(--white-color);

        &.clicked {
          color: var(--white-color);
          background: var(--dark-green-color);
        }
      }

      &.noShow {
        padding: 10px 10px;
        color: var(--secondary-color);

        &.clicked {
          height: 35px;
          width: 137px;
          color: var(--white-color);
          border: 1px solid var(--secondary-color);
          background: var(--secondary-color);
        }
      }

      &.notArrived {
        padding: 10px 10px;
        color: var(--primary-color);
      }
    }
  }
}
