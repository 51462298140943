.slots {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;

  .timeSlot {
    width: 100%;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    padding: 4px;
    background-color: var(--white-color);
    color: var(--primary-color);

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    &.selected {
      color: var(--white-color);
      background-color: #3f78af;
    }
  }
}

.disclaimer {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  gap: 20px;
  border: 1px solid var(--warning-color);
  border-radius: 4px;
  background-color: var(--shift-bg-color-not-responded, #fffbf2);

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 40px;

    .icon {
      font-size: 24px;
    }

    .title {
      font-size: 14px;
      line-height: 17.5px;
      font-weight: 500;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
}
