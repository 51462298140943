.title {
  min-width: 500px;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: var(--primary-color);

  .closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--primary-color);

  .actionButtons {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    .cancel {
      color: var(--active-color, #ff4d4d);

      &.disabled {
        opacity: 0.6;
      }
    }

    .submit {
      color: var(--white-color, #fff);
      border-radius: 4px;
      background: #3268c5;
      display: flex;
      padding: 8px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &.disabled {
        opacity: 0.6;
      }

      .loading {
        color: var(--white-color);
      }
    }
  }
}
