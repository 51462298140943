.root {
  .paper {
    min-width: 450px;
    color: var(--primary-color);

    .titleWrapper {
      padding: 32px 24px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .content {
      border: 1px solid #dae6ed;
      margin: 8px 32px;
      border-radius: 4px;
      padding: 0;

      .title {
        background-color: #dae6ed;
        text-align: center;
        padding: 8px;
        font-weight: 500;
        font-size: 14px;
      }

      .detailsList {
        list-style: none;
        margin: 20px;
        padding: 0;

        li {
          display: grid;
          grid-template-columns: 30px 1fr;
          margin: 8px 0;
          align-items: center;
        }
      }
    }
  }

  .closeButton {
    margin: 24px;
  }
}
