.calendar {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .datesList {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    > li {
      margin: 0 15px;
    }
  }

  .dayLabel {
    width: 33px;
    height: 20px;
    text-align: center;
  }

  .dayWrapper {
    padding: 4px;

    &.selected {
      background-color: var(--font-light-color, #dae6ed);
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 #00000040;
    }
  }

  .dayButton {
    width: 25px;
    height: 25px;
    padding: 0;
    border-radius: 15px;
    border: none;
    background-color: var(--date-filter-color-future);
    color: var(--white-color);
    cursor: pointer;

    &.disabled {
      background-color: #adc5dd;
    }
  }
}
